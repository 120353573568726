import PropTypes from "prop-types";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";

import PeopleIcon from "@mui/icons-material/People";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { Button } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";

import Logo from "@/assets/images/ppm-white.svg";
import colorConstants from "@/constants/color";
import layoutConstants from "@/constants/layout";

import { Business, BusinessCenterOutlined } from "@mui/icons-material";
import { clearCookies } from "@/utils/axiosInstance";
import { AuthContext } from "@/context/AuthContextProvider";
import { useKeycloak } from "@react-keycloak/web";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${layoutConstants.drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

function MainLayout({ children }) {
  const [open] = React.useState(true);
  const location = useLocation();
  const { user } = React.useContext(AuthContext);

  const goBackIntra = async (e) => {
    window.location.href = process.env.REACT_APP_INTRA_URL;
  };

  const menuItems = [
    {
      title: "Invoice",
      key: "receipts",
      children: [
        {
          title: "Account Invoices",
          key: "your_receipts",
          path: "/",
          icon: <ReceiptIcon />,
        },
      ],
    },
    {
      title: "Manage",
      key: "manage",
      roleAllowed: ["ADMIN"],
      children: [
        {
          title: "Production Supplier",
          key: "production-supplier",
          roleAllowed: ["ADMIN"],
          path: "/production-supplier",
          icon: <PeopleIcon />,
        },
        {
          title: "Support Function",
          key: "support-function",
          roleAllowed: ["ADMIN"],
          path: "/support-function",
          icon: <BusinessCenterOutlined />,
        },
        {
          title: "Supplier",
          key: "suppliers",
          roleAllowed: ["ADMIN"],
          path: "/suppliers",
          icon: <Business />,
        },
      ],
    },
  ];

  const { keycloak } = useKeycloak();

  const handleLogout = () => {
    clearCookies();
    keycloak.logout({
      redirectUri: process.env.REACT_APP_INTRA_URL,
    });
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="transparent"
        sx={{
          ...(open && {
            width: `calc(100% - ${layoutConstants.drawerWidth}px)`,
            marginLeft: `${layoutConstants.drawerWidth}px`,
          }),
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Toolbar>
            <div className="text-primary font-semibold ml-6">
              Supplier Platform
            </div>
          </Toolbar>
          <Toolbar>
            <Button onClick={handleLogout}>Logout</Button>
          </Toolbar>
        </Stack>
      </AppBar>
      <Drawer
        sx={{
          width: layoutConstants.drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: layoutConstants.drawerWidth,
            boxSizing: "border-box",
            backgroundColor: colorConstants.primary,
          },
          "& .active": {
            textDecoration: "underline",
            backgroundColor: colorConstants.blue,
          },
          "& .MuiListItemButton-root:hover": {
            backgroundColor: colorConstants.blue,
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <div className="flex justify-center items-center my-4">
          <button onClick={goBackIntra}>
            <img src={Logo} alt="logo" />
          </button>
        </div>
        <List>
          {menuItems
            .filter((menu) => {
              if (menu.roleAllowed) {
                return menu.roleAllowed.includes(
                  user?.platform?.freelance.toUpperCase()
                );
              }
              return true;
            })
            .map((headerMenu) => (
              <List
                key={headerMenu.key}
                subheader={
                  <ListSubheader
                    sx={{
                      backgroundColor: colorConstants.primary,
                      color: colorConstants.lightgray,
                    }}
                  >
                    {headerMenu.title}
                  </ListSubheader>
                }
              >
                {headerMenu.children
                  .filter((menu) => {
                    if (menu.roleAllowed) {
                      return menu.roleAllowed.includes("ADMIN");
                    }
                    return true;
                  })
                  .map((menu) => (
                    <Link to={menu.path} key={menu.key}>
                      <ListItemButton
                        className={
                          location.pathname.includes(menu.key) ? "active" : ""
                        }
                        sx={{
                          justifyContent: open ? "initial" : "center",
                          px: 2,
                          color: colorConstants.lightgray,
                          opacity: 0.7,
                        }}
                      >
                        <div className="w-12">{menu.icon}</div>
                        <ListItemText primary={menu.title} />
                      </ListItemButton>
                    </Link>
                  ))}
              </List>
            ))}
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {children}
      </Main>
    </Box>
  );
}

MainLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default MainLayout;
