import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const expirationDate = new Date("2100-01-01T00:00:00Z");

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACK_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const refreshToken = async () => {
  const refreshToken = cookies.get("refreshToken");
  if (!refreshToken) {
    logout();
    throw new Error("No refresh token available");
  }
  try {
    const res = await axios.post(
      process.env.REACT_APP_BACK_API_URL + "/api/v1/auth/refreshToken",
      {
        refreshToken,
      }
    );
    const newAccessToken = res.data.accessToken;
    const newRefreshToken = res.data.refreshToken;
    cookies.set("accessToken", newAccessToken, {
      expires: expirationDate,
    });
    cookies.set("refreshToken", newRefreshToken, {
      expires: expirationDate,
    });
    return newAccessToken;
  } catch (error) {
    logout();
    throw error;
  }
};

const logout = async () => {
  try {
    await axios.post(
      process.env.REACT_APP_BACK_API_URL + "/api/v1/auth/logout",
      {},
      {
        headers: {
          Authorization: `Bearer ${cookies.get("accessToken")}`,
        },
      }
    );
  } catch (error) {
    console.log(error);
  } finally {
    cookies.remove("accessToken");
    cookies.remove("refreshToken");
    window.location.href = process.env.REACT_APP_INTRA_URL;
  }
};

const clearCookies = () => {
  cookies.remove("accessToken");
  cookies.remove("refreshToken");
};

// Interceptor to add access token to requests
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = cookies.get("accessToken");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor to handle 401 errors and refresh token
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const newAccessToken = await refreshToken();
          processQueue(null, newAccessToken);
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return axiosInstance(originalRequest);
        } catch (err) {
          processQueue(err, null);
          logout();
          throw err;
        } finally {
          isRefreshing = false;
        }
      } else {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axiosInstance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
export { logout, refreshToken, clearCookies };
