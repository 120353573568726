import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { AuthContext } from "@/context/AuthContextProvider";

function ProtectedRoute({ children, roleAllowed }) {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const redirectBackToHome = () => {
    navigate("/");
  };
  useEffect(() => {
    if (user && roleAllowed) {
      if (
        !user?.platform?.freelance ||
        (roleAllowed?.length > 0 &&
          !roleAllowed?.includes(user?.platform?.freelance?.toUpperCase()))
      ) {
        redirectBackToHome();
      }
    }
  }, [user]);

  return <MainLayout>{children}</MainLayout>;
}

ProtectedRoute.propTypes = {
  children: PropTypes.any.isRequired,
};

export default ProtectedRoute;
