import React, { useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import ToastMessage from "@/components/common/ToastMessage";
import { ThemeProvider } from "@emotion/react";
import theme from "@/mui/theme";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";

import UIContext from "@/context/uiContext";
import { Alert, Backdrop, CircularProgress, Snackbar } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import useUIStore, { ACTIONS } from "./store/uiStore";
import ConfirmDialog from "./components/common/ConfirmDialog";
import ProtectedRoute from "./components/guard/ProtectedRoute";

import SingleSupplier from "./views/suppliers/SingleSupplier";
import { AuthContextProvider } from "./context/AuthContextProvider";

import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloakAuthClient from "./keycloak";
import Cookies from "universal-cookie";

const Login = React.lazy(() => import("./views/Login"));
const Home = React.lazy(() => import("./views/home"));
const Freelancer = React.lazy(() => import("./views/freelancer"));
const Supplier = React.lazy(() => import("./views/suppliers"));
const SupportFunctionReceipts = React.lazy(() =>
  import("./views/support-function")
);

const MainView = () => {
  const routes = useRoutes([
    {
      path: "",
      element: (
        <ProtectedRoute>
          <Home />
        </ProtectedRoute>
      ),
      index: true,
    },
    {
      path: "production-supplier",
      element: (
        <ProtectedRoute roleAllowed={["ADMIN"]}>
          <Freelancer />
        </ProtectedRoute>
      ),
    },
    {
      path: "suppliers",
      children: [
        {
          path: ":id",
          element: (
            <ProtectedRoute roleAllowed={["ADMIN"]}>
              <SingleSupplier />
            </ProtectedRoute>
          ),
        },
        {
          path: "",
          element: (
            <ProtectedRoute roleAllowed={["ADMIN"]}>
              <Supplier />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "support-function",
      element: (
        <ProtectedRoute roleAllowed={["ADMIN"]}>
          <SupportFunctionReceipts />
        </ProtectedRoute>
      ),
    },
    { path: "login", element: <Login /> },
  ]);
  return routes;
};

function App() {
  const { state, dispatch } = useUIStore();
  const [keycloakInitialized, setKeycloakInitialized] = useState(false); // State to track Keycloak initialization
  const cookies = new Cookies();

  const handleClose = () => {
    dispatch({
      type: ACTIONS.toggleLoading,
      payload: true,
    });
  };

  const handleOnToken = (token) => {
    cookies.set("accessToken", token.token);
    cookies.set("refreshToken", token.refreshToken);
    setKeycloakInitialized(true); // Set state to true once tokens are set
  };

  const handleKeycloakEvent = (event, error) => {
    if (event === "onAuthSuccess" || event === "onReady") {
      setKeycloakInitialized(true); // Set state when Keycloak is ready
    }
    if (event === "onAuthLogout") {
      setKeycloakInitialized(false); // Reset state on logout
    }
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloakAuthClient}
      onTokens={handleOnToken}
      onEvent={handleKeycloakEvent}
      initOptions={{
        onLoad: "check-sso",
        checkLoginIframe: false,
      }}
    >
      <AuthContextProvider>
        <Router>
          <UIContext.Provider
            value={{
              state,
              dispatch,
            }}
          >
            <ToastMessage
              message={state.toastMessage.message}
              type={state.toastMessage.type}
              open={state.toastMessage.message !== ""}
              handleClose={() =>
                dispatch({
                  type: ACTIONS.setToastMessage,
                  payload: { message: "" },
                })
              }
            />
            <ConfirmDialog
              open={state.confirmDialog.open}
              refreshData={state.confirmDialog.refreshData}
              callback={state.confirmDialog.callback}
              description={state.confirmDialog.description}
            />
            <ThemeProvider theme={theme}>
              {keycloakInitialized ? (
                <React.Suspense
                  fallback={
                    <Backdrop
                      sx={{ color: "#fff", zIndex: (t) => t.zIndex.drawer + 1 }}
                      open
                      onClick={handleClose}
                    >
                      <CircularProgress color="primary" />
                    </Backdrop>
                  }
                >
                  {state.loading &&
                    (state.linearLoading ? (
                      <LinearProgress className="my-4" />
                    ) : (
                      <Snackbar
                        open={state.loading}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <Alert
                          icon={
                            <CircularProgress color="primary" size="1.5rem" />
                          }
                          severity="info"
                          sx={{ width: "100%" }}
                        >
                          Loading
                        </Alert>
                      </Snackbar>
                    ))}
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <MainView />
                  </LocalizationProvider>
                </React.Suspense>
              ) : (
                // Show loading indicator until Keycloak is initialized
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open
                >
                  <CircularProgress color="primary" />
                </Backdrop>
              )}
            </ThemeProvider>
          </UIContext.Provider>
        </Router>
      </AuthContextProvider>
    </ReactKeycloakProvider>
  );
}

export default App;
