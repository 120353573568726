import axiosInstance, { clearCookies } from "@/utils/axiosInstance";
import { useKeycloak } from "@react-keycloak/web";

const useAuth = () => {
  const { keycloak } = useKeycloak();
  const getCurrentUser = async () => {
    try {
      const res = await axiosInstance.get("/auth/currentLoginUser");
      return res.data;
    } catch (error) {
      if (error.response.status === 401) {
        keycloak.logout();
        clearCookies();
      }
      throw error;
    }
  };

  return {
    getCurrentUser,
  };
};

export { useAuth };
