import { createContext, useEffect, useState } from "react";

import { useAuth } from "@/hooks/useAuth";
import { useKeycloak } from "@react-keycloak/web";

const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { getCurrentUser } = useAuth();
  const { initialized, keycloak } = useKeycloak();
  useEffect(() => {
    if (!initialized) return;
    if (!keycloak.authenticated) {
      keycloak.login();
    }
    setLoading(true);

    getCurrentUser()
      .then((data) => {
        if (data) setUser(data);
        if (
          !data ||
          (!data?.platform?.freelance &&
            data?.platform?.freelance.toLowerCase() === "na")
        ) {
          window.location.href = process.env.REACT_APP_INTRA_URL;
        }
      })

      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [initialized, keycloak]);

  return (
    <AuthContext.Provider value={{ user, setUser, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthContextProvider };
